import React from 'react';
import { BrowserRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';
import AboutUs from './pages/_AboutUs';
import Home from './pages/_Home';
import RaidRules from './pages/_RaidRules';
import GuildRoster from './pages/_GuildRoster';
import Logs from './pages/_Logs';
import Rules from './pages/_Rules';
import BankRules from './pages/_Rules_bank';
import GlobalRules from './pages/_Rules_global';
import CraftingRules from './pages/_Rules_crafting';
import ActivityRules from './pages/_Rules_activity';

function Routing() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/rules" element={<Rules/>}/>
        <Route path="/rules/bank" element={<BankRules/>}/>
        <Route path="/rules/global" element={<GlobalRules/>}/>
        <Route path="/rules/crafting" element={<CraftingRules/>}/>
        <Route path="/rules/activity" element={<ActivityRules/>}/>
        <Route path="/raidrules" element={<RaidRules/>}/>
        <Route path="/roster" element={<GuildRoster/>}/>
        <Route path="/logs" element={<Logs/>}/>

      </Routes>
    </HashRouter>
  );
};

export default Routing;