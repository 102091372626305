import React, { Component, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import './css/Navbar.css';
import App from './App';
import _AboutUs from './pages/_AboutUs';
import './css/App.css';

import { GlobalStyles } from '@mui/material';
import { Link } from 'react-router-dom';

import Logo from './img/OGNITL_Title.png';
import LightThemeBtn from './dev/LightThemeButton';
import DarkThemeBtn from './dev/DarkThemeButton';
import { Light } from '@mui/icons-material';
import ThemeToggle from './dev/ThemeToggle';

const Navbar = () => {
    const aboutusRef = useRef(null);
    const raidrulesRef = useRef(null);
    const rosterRef = useRef(null);
    const rulesRef = useRef(null);
    //const mythicRosterRef = useRef(null);
    const tgp2024Ref = useRef(null);

    const animDuration = '0.5s';
    const fontWeight = '700';
    const color = '#2DBAED'

    useEffect(() => {
        if (window.location.href.includes('/aboutus') && aboutusRef.current) {
            aboutusRef.current.style.animationDuration = animDuration;
            aboutusRef.current.style.fontWeight = fontWeight;
            aboutusRef.current.style.color = color;
        }
        else if (window.location.href.includes('/raidrules') && raidrulesRef.current) {
            raidrulesRef.current.style.animationDuration = animDuration;
            raidrulesRef.current.style.fontWeight = fontWeight;
            raidrulesRef.current.style.color = color;
        }
        else if (window.location.href.includes('/roster') && rosterRef.current) {
            rosterRef.current.style.animationDuration = animDuration;
            rosterRef.current.style.fontWeight = fontWeight;
            rosterRef.current.style.color = color;
        }
        else if ((window.location.href.includes('/rules')
            || window.location.href.includes('/rules/global')
            || window.location.href.includes('/rules/bank')
            || window.location.href.includes('/rules/crafting')) && rulesRef.current) {
            rulesRef.current.style.animationDuration = animDuration;
            rulesRef.current.style.fontWeight = fontWeight;
            rulesRef.current.style.color = color;
        }

    }, []);
    return (
        <AppBar position="static" className='header'>

            <GlobalStyles styles={
                {}} />
            <Toolbar>
                {/*Inside the IconButton, we  
            can render various icons*/}
                {/* <IconButton 
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }} 
                >  */}
                {/*This is a simple Menu  Icon wrapped in Icon */}
                {/* <MenuIcon /> 
            </IconButton>  */}
                {/* The Typography component applies default font weights and sizes */}
                <div className='logo'>
                    <Link to="/"><img src={Logo} alt='Our Guild Name Is Too Long'></img></Link>
                </div>
                {/* <div className='special-link'>
                    <Link to="/tgp2024" ref={tgp2024Ref}>OGNITL: TGP</Link>
                </div> */}
                <div className='section-links'>
                    <Link to="/aboutus" className='links' ref={aboutusRef}>About Us</Link>
                    <Link to="/rules" className='links' ref={rulesRef}>Rules</Link>
                    <Link to="/raidrules" className='links' ref={raidrulesRef}>Raid Rules</Link>
                    <Link to="/roster" className='links' ref={rosterRef}>Guild Roster</Link>
                    {/* <Link to="/mythicroster" className='links' ref={mythicRosterRef}>Mythic Roster</Link> */}
                </div>

                {/* Commented code cause login doesnt do anything yet and the theme swapping is c o n f u s i n g */}

                {/* <Button color="inherit">Login</Button>
                    <div id='theme-container'>
                        <ThemeToggle/>
                    </div> */}
            </Toolbar>
        </AppBar>
    )
}
export default Navbar;
